import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import "./Blog.css";
import {useParams} from "react-router-dom"
import Latex from "react-latex"

const Blog = () => {


  let { id } = useParams();
  const [blogLink, setBlogLink] = useState({format: "md"});

  useEffect(() => {
    const getBlogs = async () => {
      const url = "/getBlog/" + id;
      const resp = await fetch(url);
      const data = await resp.json();
      setBlogLink({md_string: data["string"], format: data["format"], heading: data["heading"], date: data["date"]})
      console.log(blogLink)
    }
    getBlogs();
  }, []);

  const beforelatex  = "\\documentclass[20pt]{article} \
    \\begin{document} \
  "

  const afterlatex = " \\end{document}"
  return (
    <div className='Blog'>
    <h2 className = "BlogHeading"> { blogLink.heading } </h2>
    <h3 className = "BlogDate"> {blogLink.date} </h3>
     {blogLink.format === "md" ? <ReactMarkdown>{blogLink["md_string"]}</ReactMarkdown> : <latex-js baseURL="https://cdn.jsdelivr.net/npm/latex.js/dist/">
      {beforelatex}
      {blogLink["md_string"]}
      {afterlatex}
      </latex-js>}
      
    </div>
  )
}

export default Blog