import React from 'react'
import "./Header.css"
import ButtonMailto from '../MailTo/MailTo';
const Header = () => {
  return (
    <div className="headerBlock">
        <div className='headerLeft'/>
        <div className="headerTitle">
                Hello! I am Kapil Devkota. Contact me using my TUFTS email address <ButtonMailto label="Here" mailto="mailto:kapil.devkota@tufts.edu"/>. Click <a href="https://github.com/kap-devkota">Here</a> to look at my Github repo. I don't have links to any other social media accounts. Also, Check out my <a href="https://scholar.google.com/citations?user=LFj0jasAAAAJ&hl=en&oi=ao">Google Scholar page</a>
        </div>
        <div className='headerRight'/>
    </div>
  )
}

export default Header