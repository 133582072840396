import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import "./Publication.css";
import Pdf from "../../data/CV.pdf";

const Publication = () => {
  const [publicationEntries, updatePublicationEntries] = useState([]);
  useEffect(() => {
    const fetchUpdate = async () => {
      const url = "/getPageContents/publications";
      const resp = await axios(url);
      const data = await resp.data;
      updatePublicationEntries(prev =>  data["all_pubs"].split("\n"))
    }
    fetchUpdate();
  }, [])

  useEffect(() => {console.log(publicationEntries)}, [publicationEntries])

  const pubEntry = (entry) => {
    return (
      
      <div className="pubEntry">
        <p>
        <ReactMarkdown>{entry}</ReactMarkdown>
        </p>
      </div>
    )
  }

  return (

    <div className='Publication'>
      
      <div className="pubHeader">
        <div className="pubTitle">
          All Publications
        </div>
        <a className="cvloc" href={Pdf}> Download CV</a>
      </div>

      <div className="pubs">
        {publicationEntries.map((entr) => pubEntry(entr))}
      </div>
    </div>
  )
}

export default Publication