import React, { useEffect, useState } from 'react';
import TopBar from "./components/topBar/TopBar"
import Header from "./components/header/Header"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blogs from "./pages/Blogs/Blogs"
import Publication from "./pages/Publication/Publication"
import Home from "./pages/Home/Home"
import Blog from "./pages/Blog/Blog"
import AddBlog from './pages/Blog/AddBlog';
import "./App.css"
import axios from 'axios';

function App() {
  const [loggedIn, setLoggedIn] = useState()

  useEffect(() => {
    console.log("Here")
    axios.get("/isloggedin", [])
    .then(res => {
      if (res.status === 200) {
        setLoggedIn(true)
      }
      else {
        setLoggedIn(false)
      }
    })
  })

  return (
    <Router>
      <TopBar logged={setLoggedIn}/>
      <Header />
      <div className="mainBody">
        <div className="LeftBlock"/>
        <div className="bodyBlock">
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/blogs" element={<Blogs logged={loggedIn}/>}/>
            <Route exact path="/blogs/:id" element={<Blog logged={loggedIn}/>}/>
            <Route path="/publication" element={<Publication/>}/>
            <Route path="/addblog" element = {<AddBlog />} />
          </Routes>
        </div>
        <div className="RightBlock"/>
      </div>
    </Router>
  );
}

export default App;
