import React, { useState, useEffect }from 'react';
import face from '../../images/profile.png';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'

import "./Home.css";
const Home = () => {
  const [homeEntries, updateHomeEntries] = useState({});

  useEffect(() => {
  const fetchUpdate = async () => {
    const url = "/getPageContents/home";
    const resp = await axios(url);
    const data = await resp.data;
    updateHomeEntries((prev) => (data))
  }
  fetchUpdate().catch(console.error);
}, []);


  useEffect(() => {console.log(homeEntries);}, [homeEntries])

  return (
    <div className='Home'>

<div className="homeTopTitle">
  About Myself
</div>
      <div className='homeTop'>
        <div className="homeTopBody">
          <p><ReactMarkdown>{homeEntries["home_top"]}</ReactMarkdown></p></div>
      <div className='homeFig'>
        <img src={face} className="profileImg" alt=""/>
      </div>
    </div>
    </div>
  )
}

export default Home;