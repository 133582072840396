import React, {useState} from 'react'
import "./Blog.css";
import {Link, useLocation} from "react-router-dom"
import axios from 'axios';
import { useRef } from 'react';
import "./AddBlog.css";


function AddBlog() {
  const locate = useLocation();
  const props = locate.state
  const redirect = useRef(null);

  const nameRef = useRef(null);
  const contentRef = useRef(null);

  const baseURL = (props && props.id) ? "/editBlog":"/addBlog"
  const [name, setName] = useState("")
  const [content, setContent] = useState("")
  const [format, setFormat] = useState("md")
  axios.defaults.withCredentials = true
  const handleText = (event) => {
    setName(event.target.value)
  }

  const handleContent = (event) => {
    setContent(event.target.value)
  }

  const handleSubmit = (event) => {
    console.log(baseURL)
    if (name === "" && content === "") {
        return
    }
    const name_ = (name === ""? nameRef.current.defaultValue : name)
    const content_ = (content === "" ? contentRef.current.defaultValue: content)
    const payload = {
        id: (props && props.id) ? props.id: -1,
        heading: name_,
        content: content_,
        format: format 
    }
    console.log(`payload : ${payload}, baseURL: ${baseURL}`)
    axios.post(baseURL, payload)
    .then(
        res => {
            if (res.status === 200) {
                redirect.current.click();
            }
            else {
              console.log(res)
            }
        }
    )
  }


  const handleFormat = (event) => {
    setFormat(event.target.value)
  }

  return (
    <div className='addBlog'>Add a new blog entry
        <textarea ref = {nameRef} className="blgName" onChange = {handleText} defaultValue = {(props && props.heading) ? props.heading: ""}/>
        <textarea ref = {contentRef} className="blgContent" onChange = {handleContent} defaultValue = {(props && props.content) ? props.content: ""}/>
        <select className='select_format' value={format} onChange={handleFormat}>
          <option value="md">MarkDown</option> 
          <option value="latex">LaTeX</option>
        </select>
        <button className="submit" onClick = {handleSubmit}> Submit </button> 

        <Link ref={redirect} to={"/blogs"}/>
    </div>
  )
}

export default AddBlog