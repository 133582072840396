import React, {useCallback} from 'react'
import "./TopBar.css"
import Profile from "../../resources/profile.jpg"
import {Link, useNavigate} from "react-router-dom"
import Pdf from "../../data/CV.pdf";
import { useState } from 'react';
import axios from "axios"; 

const TopBar = (props) => {

  const baseURL = "/login"
  const navigate = useNavigate();
  const [keyf, setKeyF] = useState("");
  const [logint, setLoginT]  = useState("Login");
  const [login, setLogin] = useState(false);
  const [display, setDisplay] = useState("none");

  axios.defaults.withCredentials = true


  const handlePriv = (event) => {
    console.log(event.currentTarget.files[0]);
    const reader  = new FileReader();
    reader.onload = async (e) => {
        setKeyF(e.target.result)
    };
    reader.readAsText(event.target.files[0])
  }

  const handleLogin = (event) => {
    if(login) {
        setDisplay("none")
        if (keyf !== "") {
            const payload = {
                name: "kapil",
                privkey: keyf
            }
            axios.post(baseURL, { payload })
            .then(
                res => {
                    if(res.status === 200) {
                        props.logged(true)
                    }
                    else {
                        props.logged(false)
                    }
                    console.log(res)
                }
            )
    
        }
    }
    else {
        setDisplay("inline")
        setKeyF("")
    }
    setLogin(!login)
    setLoginT(logint === "Login" ? "Submit": "Login")

  }


  const handleOnClick = useCallback(() => navigate("/"
  , {replace:true,}), [navigate]);

  return (
    <div className='topBar'>
        <div className='topBarLeft'>
            <img className="tpProfile" 
                onClick={handleOnClick}
                src={Profile} 
                alt=""
            />
        </div>
        <div className="topBarCenter">
            <ul className="tblItems">
                <Link className="tblItem" to="/publication">Publication</Link>
                <a className="tblItem" href={Pdf}>CV</a>
                <Link className="tblItem" to="/blogs">Blogs</Link>
            </ul>
        </div>
        <div className="topBarRight">
            <input type="file" id="file" name="file" onChange={handlePriv} multiple style={{display:"none"}}/>
            <label for="file" className='inputfile' style={{"display":display, "margin-left": "20px"}} >Private Key File</label>
            <div className="login" style={{"margin-left":"20px"}} onClick={handleLogin}>{logint}</div>
        </div>
    </div>
  )
}

export default TopBar