import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react'
import { Link } from "react-router-dom"
import "./Blogs.css"

const Blogs = (props) => {

  const [blogLink, setBlogLink] = useState([]);
  const [update, setUpdate] = useState(true);
  const [editState, setEditState] = useState(null);
  const [error, setError] = useState("")
  const linkRef = useRef(null)
  const linkBlog = useRef(null)
  axios.defaults.withCredentials = true
  const deleteURL = "/removeBlog"
  
  useEffect(async  () => {
    const getBlogs = async () => {
      const blogsURL = "/getBlogs";
      axios.get(blogsURL, {})
      .then(res => {setBlogLink([]); return res;})
      .then(
        res => {
          res.data.map(entry => {setBlogLink(prev => [...prev, 
            {name: entry['name'],
          id: entry['id'], 
          date: entry['date']}]); return 0;})
          return res;
        }
      )
    }
    await getBlogs();
  }, [update]);


  const handleDelete = (event) => {
    const payload = {id: event.target.dataset['id']}
    axios.post(deleteURL, payload)
    .then(
      res => {
        if (res.status === 200) {
          setUpdate(!update)
          //linkBlog.current.click()
        }
        else {
          setError("Error while deleting the blog entry.")
        }
      }
    )
  }

  const getLink = (blog) => {
    return (
      <div className="BlogItem" key={blog['id']}>
        {props.logged ? <div className="options" style ={{"display":"flex","flex-direction":"row"}}>
          <div className="editBlog" data-id={blog['id']} onClick={handleEdit}>[edit]</div>
          <div className="deleteBlog" data-id={blog['id']} onClick={handleDelete}>[del]</div>
        </div>:""}
        <Link className="BlogItemName" to={"/blogs/" + blog['id']}>
        {blog['name']}
        </Link>
        <div className="BlogItemDate">{blog['date']}</div>
      </div>
    )
  }

  const handleAddClick = (env) => {
    const addAndClick = async () => {
      setEditState(null)
    }
    addAndClick().then(() => {
        linkRef.current.click()
      }
    )
  }

  const handleEdit = (event) => {
    const id = event.target.dataset['id']
    const getBlogURL = `/getBlog/${id}`
    axios.get(getBlogURL, [])
    .then(
      res => {
        const edt = {
          id : id,
          heading: res.data['heading'],
          content: res.data['string']
        }
        setEditState(edt);
        return edt;
      }
    )
    .then( () => {
      linkRef.current.click();
      }
    )
  }

  return (
    <div className='Blogs'>  

      <span className='BlogTitle'>
       <span className="blogT">Blogs</span>
      {props.logged ? <span className="adminMode" onClick={handleAddClick}>[+]</span>: ""}</span>
      <ul className='BlogEntry'>{blogLink.map(blog => getLink(blog))}</ul>
      <Link ref={linkRef} state={editState} to="/addblog"/>
      <Link ref={linkBlog} to="/blogs"/>
      <div className="errorLocation" style = {{"color":"red", "fontSize": "xx-large"}}>{error}</div>
    </div>
  )
}

export default Blogs
